import React from "react";
import { PricingCard } from "../pricingCard/PricingCard";

import {
  Card,
  CardContent,
} from "@repo/ui/components/ui/card";
import { components } from '../../services/api/openapi';


interface PricingTableProps {
  pricingDefinitions: components["schemas"]["PricingDefinition"][]
}

const PricingTable: React.FC<PricingTableProps> = ({ pricingDefinitions }) => {
  return (
    <Card className="border-0 shadow-none">
      <CardContent className="flex w-full gap-4">
        {pricingDefinitions.map((def) => {
          return (
            <PricingCard
              key={`monthly-${def.plan.name}`}
              variant="monthly"
              name={def.plan.name}
              description={def.plan.description}
              currency={def.billing.currency}
              priceAmountMonthly={def.billing.monthly.price_amount}
              priceAmountYearly={0}
              priceLabelMonthly="/mo"
              priceLabelYearly="/yr"
              ctaText="Select plan"
              ctaLink={def.billing.monthly.payment_link}
              features={def.plan.features}
              limits={def.plan.limits}
            />
          )
        })}
      </CardContent>
    </Card>
  )
}

PricingTable.displayName = "PricingTable";

export { PricingTable };
