import { SignedIn, SignedOut, useAuth, useUser } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from "react";
import { Link, Navigate, Outlet, matchPath, useLocation } from "react-router-dom";
import { routesSections, type FirstLevelNonIndexRouteConfig, type RoutesSectionsConfig } from "../config/routes";
import { Menu, TopBar, type MenuItemFlatObject, type MenuItemGroupObject, type MenuSection } from "../organisms";
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';
import logo from '/logo_transparent_bg.png';

const CONTACT_EMAIL = import.meta.env.VITE_CONTACT_EMAIL;
const BETA_PHASE_ACTIVE = import.meta.env.VITE_BETA_PHASE_ACTIVE;
const CTA_URL = "/workspaces/create";

const parseMenuSections = (sections: RoutesSectionsConfig, currentPath: string): MenuSection[] => {
  return sections
    .map(section => {
      const sectionPath = section.path;
      return {
        ...section,
        items: section.items
          .filter(route => !("index" in route && route.index))
          .filter(route => !("noMenu" in route && route.noMenu))
          .map(route => {
            const firstLevelNonIndexRoute = route as FirstLevelNonIndexRouteConfig;
            const subSectionPath =
              sectionPath ?
                `${sectionPath}/${firstLevelNonIndexRoute.path}` :
                firstLevelNonIndexRoute.path;
            const match = matchPath({ path: subSectionPath, end: false }, currentPath);
            if (firstLevelNonIndexRoute.subItems) {
              const subItems = firstLevelNonIndexRoute.subItems
                .filter(route => !("noMenu" in route && route.noMenu))
                .map(subItem => {
                  const subSubSectionPath = `${subSectionPath}/${subItem.path}`
                  const match = matchPath({ path: subSubSectionPath, end: false }, currentPath);
                  return {
                    ...subItem,
                    path: subSubSectionPath,
                    isActive: match !== null,
                  }
                });

              if (subItems.length > 0) {
                return {
                  ...route,
                  subItems
                } as Omit<MenuItemGroupObject, "value">
              } else {
                return {
                  ...route,
                  path: subSectionPath,
                  isActive: match !== null,
                } as MenuItemFlatObject
              }
            } else {
              return {
                ...route,
                path: subSectionPath,
                isActive: match !== null,
              } as MenuItemFlatObject
            }
          })
      }
    });
};

const useCreateOrUpdateProfile = (): components["schemas"]["FullProfile"] | null => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const { user, isSignedIn } = useUser();

  const { isSuccess: profileExists, isFetched: profileChecked } = useQuery({
    queryKey: ['get', 'profiles'],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/profiles", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    retry: (failureCount, error) => {
      if (error.cause.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
    enabled: isSignedIn
  })

  const profileCreation = useMutation({
    mutationFn: async (profilePayload: components["schemas"]["CreateProfileRequest"]) => {
      const { data, error, response } = await fetcher.POST("/profiles", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: profilePayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get', 'profile'] });
    },
  })

  const profileUpdate = useMutation({
    mutationFn: async (profilePayload: components["schemas"]["UpdateProfileRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/profiles", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: profilePayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get', 'profile'] });
    },
  })

  useEffect(() => {
    if (profileChecked) {
      if (!profileExists) {
        const profileCreatePayload = user ? {
          first_name: user.firstName || "",
          last_name: user.lastName || "",
          email: user.primaryEmailAddress?.emailAddress || "",
          invitation_key: localStorage.getItem('invitationKey') || "",
        } : null;

        if (profileCreatePayload) {
          profileCreation.mutate(profileCreatePayload)
        }
      } else {
        profileUpdate.mutate({})
      }
    }
  }, [profileExists, profileChecked, user?.id])

  return profileCreation.data?.data || profileUpdate.data?.data || null;
}

const RootSignedInContent = () => {
  const location = useLocation();
  const menuSections = parseMenuSections(routesSections, location.pathname);

  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const [showCTA, setShowCTA] = useState<boolean | null>(true);

  const {
    data: workspacesData,
    error: workspacesError,
    status: workspacesStatus
  } = useQuery({
    queryKey: ["get", "workspaces"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/workspaces", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (workspacesStatus === "success") {
      const workspaces = workspacesData.data;
      setShowCTA(
        workspaces.length === 0 &&
        location.pathname !== CTA_URL &&
        !location.pathname.startsWith("/user-management")
      );
    }
  }, [workspacesStatus, workspacesData, location.pathname])

  if (workspacesStatus === "pending" || showCTA === null) {
    return (
      <Spinner />
    )
  }

  if (workspacesStatus == "error") {
    return (
      <span>
        Error: {workspacesError.message}
      </span>
    )
  }

  return (
    <>
      <Menu
        productName="HeyEcho"
        productLogo={logo}
        sections={menuSections}
      />
      <div className="flex flex-col w-full h-screen px-12 py-4 gap-4">
        <TopBar />
        {showCTA ? (
          <div className="flex flex-col w-full h-full items-center justify-center">
            <Card className="min-w-80 w-80">
              <CardHeader>
                <CardTitle className="text-center">Get started</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Create a workspace now to get started!</p>
              </CardContent>
              <CardFooter>
                <Button className="w-full" aria-label="start" asChild>
                  <Link to={CTA_URL}>
                    Create workspace
                  </Link>
                </Button>
              </CardFooter>
            </Card>
          </div>
        ) :
          <Outlet />
        }
      </div>
    </>
  );
}


const RootSignedIn = () => {
  const user = useCreateOrUpdateProfile();

  if (!user) {
    return null;
  }

  const isEnabled = user.is_enabled;

  return (
    <div className="flex w-full min-h-screen">
      {isEnabled ? (
        <RootSignedInContent />
      ) : (
        <div className="flex flex-col w-full min-h-screen items-center justify-center">
          <Card className="min-w-80 w-80">
            <CardHeader>
              <CardTitle className="text-center">You account is not enabled</CardTitle>
            </CardHeader>
            <CardContent className="text-center flex flex-col gap-2">
              {BETA_PHASE_ACTIVE && (
                <p>
                  During the beta phase the product is invite-only.
                </p>
              )}
              <p>
                Please contact us.
              </p>
            </CardContent>
            <CardFooter>
              <Button className="w-full" aria-label="start" asChild>
                <a href={`mailto:${CONTACT_EMAIL}`}>
                  Contact us
                </a>
              </Button>
            </CardFooter>
          </Card>
        </div>
      )}
    </div>
  )
}


const Root = () => {
  return (
    <>
      <SignedIn>
        <RootSignedIn />
      </SignedIn>
      <SignedOut>
        <Navigate to="/auth/sign-in" />
      </SignedOut>
    </>
  )
}

export { Root };
