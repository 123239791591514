import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@repo/ui/components/ui/avatar";
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';
import { usePrefsStore } from '../store';

import { Badge } from "@repo/ui/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@repo/ui/components/ui/table";
import React from "react";

interface ContentBlogPostsListProps {}

const ContentBlogPostsList: React.FC<ContentBlogPostsListProps> = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const { data, error, status } = useQuery({
    queryKey: ["get", "blogPosts", currentWorkspace?.id],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/blog-posts", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: ["author"],
            workspace_id: currentWorkspace?.id,
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }


  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="bg-white rounded-lg border w-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Created By</TableHead>
              <TableHead>User Journey Stage</TableHead>
              <TableHead>Search Intent</TableHead>
              <TableHead>Created At</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.data.map(bp => {
              const options = { year: "numeric", month: "long", day: "numeric" };
              const formattedCreatedAt = (new Date(bp.created_at)).toLocaleDateString("en-US", options);
              const authorData = data.extra ? data.extra[bp.id] : {};
              const authorFullName = `${authorData.first_name} ${authorData.last_name}`.trim()
              const avatarFallback = (
                (authorData?.first_name ? authorData?.first_name[0] || "" : "") +
                (authorData?.last_name ? authorData?.last_name[0] || "" : "")
              )

              return (
                <TableRow
                  key={`blogPost-${bp.id}`}
                  className="hover:cursor-pointer"
                  onClick={() => navigate(`/content/blog-posts/edit/${bp.id}`)}
                >
                  <TableCell className="font-semibold">{bp.title}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Avatar>
                        <AvatarImage src={authorData?.image_url} alt="avatar" />
                        {avatarFallback && <AvatarFallback>{avatarFallback}</AvatarFallback>}
                      </Avatar>
                      {authorFullName}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge variant="outline" className="capitalize">
                      {bp.user_journey_stage}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Badge variant="outline" className="capitalize">
                      {bp.search_intent}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Badge variant="outline" className="capitalize">
                      {formattedCreatedAt}
                    </Badge>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>

  )
}

ContentBlogPostsList.displayName = "ContentBlogPostsList";

export { ContentBlogPostsList };
